
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

export default defineComponent({
  name: 'AllocDoc',
  components: { SearchTable },
  setup() {
    const { https, mapper } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'no',
              label: '划拨单号'
            },
            {
              field: 'type',
              label: '划拨类型',
              type: 'select',
              options: mapper?.device.allocType
            },
            {
              field: 'operatorId',
              label: '划拨人',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'agentId',
              label: '接收人',
              type: 'agent'
            },
          ]
        },
        remote: {
          action: https?.device.pageAllocDoc
        }
      },
      cols: [
        {
          field: 'col-basic',
          label: '划拨信息',
          width: 300,
          group: [
            {
              field: 'no',
              label: '单号',
              ability: 'copy'
            },
            {
              field: 'type',
              label: '类型',
              options: mapper?.device.allocType
            },
            {
              field: 'status',
              label: '状态',
              options: mapper?.device.allocStatus
            },
          ]
        },
        {
          field: 'col-nos',
          label: '设备编号',
          group: [
            {
              field: 'startSn',
              label: '起始'
            },
            {
              field: 'endSn',
              label: '结束'
            },
            {
              field: 'num',
              label: '数量'
            }
          ]
        },
        {
          field: 'col-device-type',
          label: '设备信息',
          group: [
            {
              field: 'modelIdTag',
              label: '型号'
            },
            {
              field: 'backerNo',
              label: '品牌号',
              options: mapper?.device.backerNo
            },
          ]
        },
        {
          field: 'col-operator',
          label: '划拨人',
          group: [
            {
              field: 'operatorAlias',
              label: '用户名'
            },
            {
              field: 'operatorPhone',
              label: '手机号'
            },
            {
              field: 'operAwardPolicyTag',
              label: '返现政策'
            }
          ]
        },
        {
          field: 'col-agent',
          label: '接受人',
          group: [
            {
              field: 'agentAlias',
              label: '用户名'
            },
            {
              field: 'agentPhone',
              label: '手机号'
            },
            {
              field: 'agentAwardPolicyTag',
              label: '返现政策'
            }
          ]
        },
        // {
        //   field: 'activeDesc',
        //   label: '激活政策'
        // },
        {
          field: 'remark',
          label: '备注',
          width: 120,
        },
        {
          field: 'col-time',
          label: '时间',
          width: 220,
          group: [
            {
              field: 'createTime',
              label: '创建时间'
            },
            {
              field: 'updateTime',
              label: '划拨时间'
            }
          ]
        }
      ]
    };

    return { tableOpts };
  }
});
